<template>
  <b-card>
    <b-form>
      <b-row>
        <b-col>
          <br />
          <b-form-group label-cols-md="4" label="Region">
            <v-select
              v-model="fieldForm.region"
              label="name"
              placeholder="Pilih Region"
              :options="optionRegion"
              @input="changeSelectRegion"
            />
          </b-form-group>
          <!-- <form-input
            v-model="fieldForm.number"
            label="Nomor SPB"
            placeholder="Nomor SPB"
          /> -->
          <b-form-group label-cols-md="4" label="Tanggal SPB">
            <flat-pickr
              v-model="fieldForm.date"
              class="form-control"
              placeholder="Tanggal SPB"
            />
          </b-form-group>
          <!-- <form-input
            v-model="fieldForm.contract_number"
            label="Nomor Kontrak"
            placeholder="Nomor Kontrak"
          /> -->
          <b-form-group label-cols-md="4" label="Nomor Kontrak">
            <v-select
              v-model="fieldForm.contract"
              label="number"
              placeholder="Pilih Kontrak"
              :options="optionContract"
              @input="changeSelectContract"
            />
          </b-form-group>
          <form-input
            v-model="fieldForm.contract_subject"
            rules="required"
            label="Judul Kontrak"
            placeholder="Judul Kontrak"
          />
          <b-form-group label-cols-md="4" label="Vendor">
            <v-select
              v-model="fieldForm.vendor"
              label="name"
              placeholder="Pilih Vendor"
              :options="optionVendor"
              @input="changeSelectVendor"
            />
          </b-form-group>
          <b-form-group label-cols-md="4" label="Sumber Dana Skko">
            <v-select
              v-model="fieldForm.skko"
              label="number"
              placeholder="Pilih Skko"
              :options="optionSkko"
              @input="changeSelectSkko"
            />
          </b-form-group>
          <b-form-group label-cols-md="4" label="Tanggal Tenggat Waktu">
            <flat-pickr
              v-model="fieldForm.shipping_date"
              class="form-control"
              placeholder="Tanggal Tenggat Waktu"
            />
          </b-form-group>
          <b-form-group label-cols-md="4" label="RAB">
            <b-form-checkbox
              v-model="fieldForm.is_rab"
              switch
              >NON RAB / RAB</b-form-checkbox
            >
          </b-form-group>
          <b-form-group label-cols-md="4" label="Tembusan">
            <div class="row">
              <div class="col-md-9">
                <form-input
                  v-model="copy"
                  :label="''"
                  :size="'0'"
                  placeholder="Tembusan"
                />
              </div>
              <div class="col-md-2">
                <b-button variant="success" type="button" @click.prevent="addCopies">
                  <span>Tambah</span>
                </b-button>
              </div>
            </div>
          </b-form-group>
          <b-form-group label-cols-md="4" label="" v-if="arr_copies.length > 0">
            <div class="row">
              <div class="col-md-4" v-for="(copy, index) in arr_copies" :key="index">
                <p>{{index+1}}. {{copy}} &nbsp;
                  <b-button
                    size="sm"
                    variant="flat-danger"
                    class="mr-1 btn-icon"
                    @click.prevent="rmCopies(index)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </p>
              </div>
            </div>
          </b-form-group>
          <b-form-group label-cols-md="4" label="">
            <b-button
              class="mr-1"
              variant="primary"
              type="button"
              @click.prevent="validationForm"
            >
              <span v-if="loading">
                <b-spinner small />
                Loading...
              </span>
              <span v-else>Simpan</span>
            </b-button>
            <b-button class="mr-1" variant="warning" type="button" @click.prevent="batal">
              <span>Batal</span>
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard,
  BCol,
  BRow,
  BButton,
  BBadge,
  BForm,
  BFormGroup,
  BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue'
import FormInput from '@/views/base/form/FormInput.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BForm,
    BButton,
    BFormGroup,
    FormInput,
    vSelect,
    BFormCheckbox,
    BSpinner,
    flatPickr,
  },
  data() {
    return {
      loading: false,
      optionRegion: [],
      optionContract:[],
      optionVendor: [],
      optionSkko: [],
      idForm: null,
      fieldForm: {
        region: null,
        vendor: null,
        region_id: null,
        number: null,
        date: null,
        contract_number: null,
        contract_subject: null,
        vendor_id: null,
        shipping_date: null,
        is_rab:false,
        skko_id:null,
        skko:null,
        copies: [],
      },
      arr_copies:[],
      copy:null
    }
  },
  mounted() {
    this.getRegion()
    this.getVendor()
    this.getContract()
    if (this.$route.params.id != null) {
      this.$http.get('/spb/' + this.$route.params.id).then(res => {
        this.idForm = res.data.id
        this.fieldForm = {
          region: res.data.region,
          vendor: res.data.vendor,
          contract: res.data.contract,
          region_id: res.data.region.id,
          number: res.data.number,
          date: res.data.date,
          contract_number: res.data.contract_number,
          contract_subject: res.data.contract_subject,
          vendor_id: res.data.vendor.id,
          shipping_date: res.data.shipping_date,
          is_rab:res.data.is_rab,
          skko_id:res.data.skko_id,
          skko:res.data.skko,
          copies: res.data.copies?JSON.parse(res.data.copies):[]
        }
        this.arr_copies = res.data.copies?JSON.parse(res.data.copies):[]
        this.getSkko();
      })
    }
  },
  methods: {
    getRegion() {
      this.$http.get('region?length=').then(res => {
        this.optionRegion = res.data.data
      })
    },
    getContract() {
      this.$http.get('contract/all').then(res => {
        this.optionContract = res.data
      })
    },
    getVendor() {
      this.$http.get('vendor?length=').then(res => {
        this.optionVendor = res.data.data
      })
    },
    getSkko() {
      this.fieldForm.skko = null;
      this.$http.get('skko?filter[vendor_id]='+this.fieldForm.vendor_id+'&length=').then(res => {
        this.optionSkko = res.data.data
      })
    },
    validationForm() {
      this.loading = true
      if(this.arr_copies.length > 0){
        this.fieldForm.copies = this.arr_copies
        this.fieldForm.copies = JSON.stringify(this.fieldForm.copies)
      }
      else
        this.fieldForm.copies = null
      
      if (this.$route.params.id == null)
        this.$http
          .post('/spb', this.fieldForm)
          .then(res => {
            this.idForm = res.data.data.id
            this.successSubmit()
            this.$store.dispatch('sendMessage', '1')
          })
          .catch(err => {
            this.errorSubmit(err)
          })
          .finally(() => {
            this.loading = false
          })
      else
        this.$http
          .put('/spb/' + this.$route.params.id, this.fieldForm)
          .then(() => {
            this.successSubmit()
          })
          .catch(err => {
            this.errorSubmit(err)
          })
          .finally(() => {
            this.loading = false
          })
    },
    changeSelectRegion(data) {
      if (data != null) {
        this.fieldForm.region_id = data.id
        this.fieldForm.region.id = data.id
        this.fieldForm.region.name = data.name
      }
    },
    changeSelectContract(data) {
      if (data != null) {
        this.fieldForm.contract_number = data.number
        this.fieldForm.contract_subject = data.subject
        this.fieldForm.vendor = data.vendor
        this.fieldForm.vendor_id = data.vendor.id
        this.fieldForm.vendor.id = data.vendor.id
        this.fieldForm.vendor.name = data.vendor.name
      }
      this.getSkko()
    },
    changeSelectVendor(data) {
      if (data != null) {
        this.fieldForm.vendor_id = data.id
        this.fieldForm.vendor.id = data.id
        this.fieldForm.vendor.name = data.name
      }
      this.getSkko()
    },
    changeSelectSkko(data) {
      if (data != null) {
        this.fieldForm.skko_id = data.id
        this.fieldForm.skko.id = data.id
        this.fieldForm.skko.number = data.number
      }
    },
    successSubmit() {
      this.loading = false
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Submit Success',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
      if (this.ismodal) {
        this.$emit('onsubmite', true)
      } else {
        this.$router.push({ name: 'spb-detail', params: { id: this.idForm } })
      }
    },
    errorSubmit(err) {
      const msg = err.response.data.message
      this.$bvToast.toast(msg ? msg : 'Submit error', {
        title: 'Error',
        solid: true,
        variant: 'danger',
      })
    },
    batal() {
      this.$router.go(-1)
    },
    addCopies(){
      if(this.copy){
        this.arr_copies.push(this.copy)
      }
      this.copy = ''
      // alert(JSON.stringify(this.fieldForm.copies))
    },
    rmCopies(index){
      this.arr_copies.splice(index, 1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
